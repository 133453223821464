<template>
  <div class="card mb-4">
    <div class="card-image">
      <figure
        @click="showDetails()"
        class="imgconteiner"
        v-bind:class="{
          imgvisible: imgLoaded
        }"
      >
        <img
          :src="$mySite.imagesURL + item.images.image"
          alt=""
          v-show="imgLoaded"
          @load="imgLoaded = true"
        />
      </figure>
      <div class="has-text-centered imgpreloader" v-show="!imgLoaded">
        <i class="fas fa-circle-notch fa-spin fa-2x"></i>
      </div>
    </div>
    <div class="card-content">
      <div class="media">
        <div class="media-content">
          <a @click="showDetails()" class="dditemtitle is-6 is-uppercase">{{
            item.title
          }}</a>
          <!-- <p>{{ item.description }}</p> -->
          <div class="ddseparator mt-2"></div>
        </div>
      </div>

      

      <div class="content">
        <div class="tags" v-if="tags">
          <span v-for="(tag,index) in tags" :key="tag+index">
            <a class="tag is-light" @click="filterTag(tag,'is-primary')" v-html="tag"></a>
          </span>
        </div>
        <div class="tags" v-if="marks">
          <span v-for="(mark,index) in marks" :key="mark+index">
            <a class="tag is-danger" @click="filterTag(mark,'is-danger')" v-html="mark"></a>
          </span>
        </div>
      </div>
    </div>
    <footer class="card-footer">
      <div class="card-footer-item">
        <ItemLocation :islocation="item.location" />
      </div>
      <a
        v-if="(item.location == 1) & !isRequested"
        @click="addR(item.id)"
        class="card-footer-item card-bt"
        >Request</a
      >
      <a
        v-if="isRequested"
        @click="removeR(item.id)"
        class="card-footer-item has-text-danger card-bt"
        >Unrequest</a
      >
    </footer>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ItemLocation from "@/components/ItemLocation.vue";

export default {
  name: "item",
  props: {
    indata: Object
  },
  components: {
    ItemLocation
  },
  data() {
    return {
      imgLoaded: false
    };
  },
  methods: {
    filterTag(tag,type) {
      this.$store.commit("SET_FILTER_TAG", {"tag":tag,"type":type});
    },
    addR(id) {
      //console.log(id);
      this.$store.commit("ADD_NUM", id);
    },
    removeR(id) {
      this.$store.commit("REM_NUM", id);
    },
    showDetails() {
      this.$store.commit("SET_ITEMDATA", this.indata);
      this.$store.commit("SHOW_MODAL", { modal: "itemShow", state: true });
    }
  },
  beforeMount() {
    //console.log("indata:" + this.indata.id);
  },
  computed: {
    ...mapState(["rNum"]),
    item: {
      get: function() {
        return this.indata;
      }
    },
    tags: {
      get: function() {
        return this.indata.tags.split(",");
      }
    },
    marks: {
      get: function() {
        if(this.indata.marks) return this.indata.marks.split(",");
      }
    },
    isRequested: {
      get: function() {
        return this.$store.getters.REQUESTS.includes(this.indata.id);
      }
    }
  }
}; //end export
</script>

<style scoped>
.iconmargin {
  margin-right: 5px;
}
.tags span {
  margin-right: 5px;
}
.imgconteiner {
  cursor: pointer;
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -ms-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
  opacity: 0;
}
.imgvisible {
  opacity: 1;
}
.imgpreloader {
  padding: 100px;
  color: #79b943;
}
.card-bt{
  font-family: 'Poppins', sans-serif;
  text-transform: uppercase;
  font-weight: 600!important;
}
/* .equal-height {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.equal-height .card-content {
  flex: auto;
}
.card-footer {
  margin-top: auto;
} */
</style>
