<template>
  <div>
    <b-loading
      :is-full-page="true"
      :active.sync="isLoading"
      :can-cancel="false"
    ></b-loading>
    <section class="hero is-small header">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-desktop is-vcentered">
            <div class="column is-one-quarter">
              <img :src="this.$mySite.logosURL+logo" width="300px" />
            </div>
            <div class="column" @keyup.enter.exact="doSearch()">
              <autocomplete
                :search="search"
                :get-result-value="getResultValue"
                @submit="handleSubmit"
              ></autocomplete>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section mainsection">
      <div class="container">
        <div class="columns">
          <div class="column is-2">
            <FilterForm
              v-on:myEvent="catchEmited"
              :loc="isLocation"
              :key="currentPage"
              :inAllCat=allCat
            />
          </div>
          <div class="column">
            <div v-if="isTagActive" class="mb-5">
              Filterted by:
           <div class="field">
                <b-tag
                  v-if="isTagActive"
                  :type="tagType"
                  closable
                  aria-close-label="Close tag"
                  @close="closeTag()"
                >
                  {{ tagFilter }} {{ markFilter }} 
                </b-tag>
              </div>
            </div>
            <ItemsList
              v-if="hasItems"
              :itemsData="listItems"
              :totalItems="totalItems"
            />
            <b-message type="is-info" v-if="!hasItems"
              >Sorry, no items were found.
            </b-message>
          </div>
        </div>
      </div>
    </section>
    <!-- section Most popular categories -->
  </div>
</template>
<script>
import { mapState } from "vuex";

import ItemsList from "@/components/ItemsList.vue";
import FilterForm from "@/components/FilterForm.vue";

export default {
  name: "home",
  components: {
    ItemsList,
    FilterForm
  },
  metaInfo() {
    return {
      title: this.$mySite.title,
      titleTemplate: "Catalog | %s",
      htmlAttrs: {
        lang: "en"
      },
      meta: [
        {
          name: "description",
          content: "Here you can find all your items stored in Indexer"
        },
        {
          property: "og:title",
          content: this.$mySite.title,
          vmid: "og:title"
        },
        {
          property: "og:description",
          content: "Here you can find all your items stored in Indexer",
          vmid: "og:description"
        },
        {
          property: "og:image",
          content: this.$mySite.image,
          vmid: "og:image"
        },
        {
          property: "og:url",
          content: this.$mySite.url,
          vmid: "og:url"
        }
      ]
    };
  }, //end meta
  data() {
    return {
      hasItems: true,
      isLoading: false,
      isCatNum: 0,
      isLocation: 1,
      startID: 0,
      totalItems: null,
      listItems: null,
      isTagActive: false,
      tagType: "is-primary",
      tagFilter: null,
      markFilter: null,
      currentSearch: null,
      currentPage: null
    };
  },
  methods: {
    catchEmited: function(e) {
      console.log(e);
      this.isLocation = e;
      
      if(this.$route.params.page!=="1"){
        this.$router.push({
          path: `/category/${this.$route.params.cname}/1`
        });
      }else{
        this.loadData();
      }
      // this.$router.push({
      //     path: `/category/${this.$route.params.cname}/1`
      //   });
    },
    search(input) {
      //const url = `${wikiUrl}/w/api.php?${params}&srsearch=${encodeURI(input)}`;
      this.currentSearch = input;
      const url = `${this.$mySite.api}/data/search.php?must=search_item&clientID=${this.isClient}&term=${encodeURI(input)}`;
      return new Promise(resolve => {
        if (input.length < 3) {
          return resolve([]);
        }

        fetch(url)
          .then(response => response.json())
          .then(data => {
            resolve(data.list);
            //console.log(data.list);
          });
      });
    },
    getResultValue(result) {
      return result.title; //result.title
    },
    handleSubmit(result) {
      //window.open(`${wikiUrl}/wiki/${encodeURI(result.title)}`)
      if(result){
        this.$store.commit("SET_ITEMDATA", result);
        this.$store.commit("SHOW_MODAL", { modal: "itemShow", state: true });
      }
      console.log(result);
    },
    loadData(search) {
      //this.startid = this.toDec(this.$route.params.stid);
      this.isLoading = true;
      //console.log("tag:" + this.$route.params.tag);
      //this.catalogSwitch();
      this.axios
        .post("data/get_data.php", {
          token: this.$store.getters.IS_JWT,
          action: "listItems",
          category: this.$store.state.filterCat, //this.$route.params.cname,
          location: this.isLocation,
          search: search,
          tag: this.tagFilter,
          mark: this.markFilter,
          lastID: (this.$route.params.page - 1) * 12
        })
        .then(response => {
          console.log(response.data);
          this.isLoading = false;
          if (response.data.success) {
            this.hasItems = true;
            this.listItems = response.data.items;
            this.totalItems = response.data.count;
            console.log("Total Result:" + this.listItems.length);
            console.log("Total DB:" + this.totalItems);
            //console.table(this.listItems);
          } else {
            this.hasItems = false;
            console.log("Error getting data");
          }
        })
        .catch(error => {
          this.isLoading = false;
          this.hasItems = false;
          console.log(error);
          //this.errored = true;
        });
      //.finally(() => (this.loading = false));
    },
    // catalogSwitch() {
    //   for (let i = 0; i < this.allCat.length; i++) {
    //     if (this.allCat[i].slug == this.$route.params.cname) {
    //       this.isCatNum = this.allCat[i].id;
    //     }
    //   }
    // },
    closeTag() {
      this.$store.commit("SET_FILTER_TAG", null);
      this.isTagActive = false;
      this.tagFilter = null;
      this.markFilter = null;
      this.$router.go(-1);
      //this.$router.push({ path: `/category/${this.$route.params.cname}`});
      //this.loadData();
    },
    doSearch(){
      if(this.currentSearch){          
          if(this.currentSearch[0]=='!'){
            this.$store.commit("SET_FILTER_TAG", {"tag":this.currentSearch.substring(1),"type":"is-danger"});
          }else if(this.currentSearch[0]=='?'){
            this.$store.commit("SET_FILTER_TAG", {"tag":this.currentSearch.substring(1),"type":"is-primary"});
          }else{
            if(this.$route.params.page!=="1"){
              this.$router.push({
                  path: `/category/${this.$route.params.cname}/1`
              });
            }else{
              this.loadData(this.currentSearch);
            }
          }
      }else{
        this.loadData();
      }
    },
    searchTag() {
      if(this.currentSearch){
        if(this.currentSearch[0]=='!'){
          this.$store.commit("SET_FILTER_TAG", {"tag":this.currentSearch.substring(1),"type":"is-danger"});
        }else{
          this.$store.commit("SET_FILTER_TAG", {"tag":this.currentSearch,"type":"is-primary"});
        }
      }
    }
  },
  mounted() {
    this.loadData();
    console.log(this.$store.getters.GET_LOGO);
    console.log(this.$store.getters.IS_JWT);
  },
  computed: {
    ...mapState(["filterTags","categories"]),
    allCat(){
      return this.categories;
    },
    logo(){
      return this.$store.getters.GET_LOGO;
    },
    isClient(){
      return this.$store.getters.IS_USER.cid;
    }
  },
  watch: {
    "$route.params.cname": function(cname) {
      this.loadData();
      // this.detectProvince();
      this.currentPage = cname;
      console.log("Category:" + cname);
    },
    "$route.params.page": function(page) {
      if(this.currentSearch){ 
        this.loadData(this.currentSearch);
      }else{
        this.loadData();
      }
      // this.detectProvince();
      console.log("Page:" + page);
    },
    filterTags(tagdata) {
      // this.loadData();
      // this.detectProvince();
      
      if (tagdata) {
        console.log("the Tag:" + tagdata.tag);
        if(tagdata.type == "is-danger"){
          this.markFilter = tagdata.tag;
        }else{
          this.tagFilter = tagdata.tag;
        }
       
        this.tagType = tagdata.type;
        this.isTagActive = true;
        if(this.$route.params.page!=="1"){
          this.$router.push({
            path: `/category/${this.$route.params.cname}/1`
          });
        }
        this.loadData();
      }
    }
  }
};
</script>
<style>
.autocomplete-input {
  background-color: #fff !important;
}
.autocomplete-result {
  color: rgb(0, 0, 0) !important;
}
</style>
