<template>
  <div class="container">
    <masonry :cols="{ default: 4, 1000: 3, 700: 2, 400: 1 }" :gutter="15">
      <Item v-for="item in itemdata" v-bind:key="item.id" :indata="item" />
    </masonry>

    <b-pagination
      class="mt-5"
      :total="total"
      :current.sync="current"
      :range-before="rangeBefore"
      :range-after="rangeAfter"
      :order="order"
      :size="size"
      :simple="isSimple"
      :rounded="isRounded"
      :per-page="perPage"
      :icon-prev="prevIcon"
      :icon-next="nextIcon"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
    >
    </b-pagination>
  </div>
  <!-- section Most popular categories -->
</template>

<script>
import Item from "@/components/Item.vue";
export default {
  name: "itemlist",
  components: {
    Item
  },
  props: {
    itemsData: Array,
    totalItems: Number
  },
  data() {
    return {
      perPage: 12,
      rangeBefore: 3,
      rangeAfter: 1,
      order: "",
      size: "",
      isSimple: false,
      isRounded: false,
      prevIcon: "chevron-left",
      nextIcon: "chevron-right"
    };
  },
  mounted() {
    //console.log("Page:" + this.curPage);
    //console.log("totalItems: " + this.totalItems);
  },
  afterMount() {
    //console.log("indata:" + this.indata.id);
    console.log("Page:" + this.curPage);
  },
  computed: {
    itemdata: {
      get: function() {
        return this.itemsData;
      }
    },
    total: {
      get: function() {
        return this.totalItems;
      }
    },
    current: {
      get: function() {
        if (this.$route.params.page !== undefined) {
          return Number(this.$route.params.page);
        } else {
          return 1;
        }
      },
      // setter
      set: function(val) {
        // this.currentPage = val;
        this.$router.push({
          path: `/category/${this.$route.params.cname}/${val}`
        });
      }
    }
  }
};
//end export
</script>
<style scoped>
/* .gridcontainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 1em;
} */
</style>
