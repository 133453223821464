<template>
  <div>
    <div class="is-divider no-top-margin" data-content="Category"></div>
    <b-menu>
      <b-menu-list>
        <b-menu-item
          v-bind:key="'cat0'"
          label="All Items"
          @click="goTo('all',0)"
          :active="$store.state.filterCat==0"
        ></b-menu-item>
        <b-menu-item
          v-for="item in allCat"
          v-bind:key="'cat' + item.id"
          :label="item.name"
          @click="goTo(item.slug,item.id)"
          :active="item.id == $store.state.filterCat"
        ></b-menu-item>
      </b-menu-list>
    </b-menu>

    <div class="is-divider" data-content="Location"></div>
    <b-switch
      v-model="isAvalibility"
      :rounded="false"
      true-value="In-House"
      false-value="Away"
      >{{ isAvalibility }}</b-switch
    >
  </div>
</template>

<script>

export default {
  name: "FilterForm",
  props: {
    loc: Number,
    inAllCat: Array
  },
  methods: {
    emit(val) {
      let location = 1;
      if (val == "In-House") {
        location = 1;
      } else {
        location = 0;
      }
      this.$emit("myEvent", location);
    },
    goTo(page,id){
      this.$store.commit("SET_FILTER_CAT", id);      
      if(this.$route.path!=='/category/' + page){
        this.$router.push({ path: '/category/' + page });
      }
    },
  },
  computed: {
    allCat(){
      return this.inAllCat;
    },
    isAvalibility: {
      get: function() {
        if (this.loc == 1) {
          return "In-House";
        } else {
          return "Away";
        }
      },
      set: function(newValue) {
        this.emit(newValue);
      }
    }
  }
};
</script>
<style scoped>
.modal-card-foot {
  justify-content: space-between; /* flex-end; */
}
.no-top-margin {
  margin-top: 0px !important;
}
.optionGroup {
  margin-top: 5px !important;
  font-weight: bold;
}
</style>
